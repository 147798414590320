import React from 'react';
import ReactDOM from 'react-dom';

function Hero2(props) {

  return (<div className="bg-image shadow-1-strong" style={{ height: 150 }}>
    <div className="mask grey-900">
        <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div className="text-white">
                <h1 className="mt-5" style={{ textTransform: 'capitalize' }}>{props.title}</h1>
            </div>
        </div>
    </div>
</div>);
}

export default Hero2;