import React, { Suspense } from "react";
import { MDBSpinner } from 'mdb-react-ui-kit';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Hero2 from './components/Hero2';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

//Pages
const Home      = React.lazy(() => import("./pages/Home"));
const Contacts  = React.lazy(() => import("./pages/Contacts"));
const Booked  = React.lazy(() => import("./pages/Booked"));
const Services  = React.lazy(() => import("./pages/Services"));
const Suite  = React.lazy(() => import("./pages/Suite"));
const Single  = React.lazy(() => import("./pages/Single"));
const Doble  = React.lazy(() => import("./pages/Doble"));
const Triple  = React.lazy(() => import("./pages/Triple"));
//components
const components = [
  '/', 'contactos', 'reservaciones', 'servicios', 'suite', 'single', 'doble', 'triple'
];

//Routes
const routes = (
  <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/contactos" exact component={Contacts} />
        <Route path="/reservaciones" exact component={Booked} />
        <Route path="/servicios" exact>
          <Services />
        </Route>
        <Route path="/suite" exact>
          <Suite />
        </Route>
        <Route path="/single" exact>
          <Single />
        </Route>
        <Route path="/doble" exact>
          <Doble />
        </Route>
        <Route path="/triple" exact>
          <Triple />
        </Route>
        <Redirect to="/" />
  </Switch>
);

function App() {
  let current = '/';
  let geturl = window.location.href;
  if(geturl){
    current = geturl.split("/").pop();
    const exist = components.filter(c => c === current);
    if(exist == ""){
      current = '';
    }
  }

  return (<Router>
      <header>
        <Navbar />
        {current == "" ? <Hero /> : <Hero2 title={current} />}
        <main className="mt-5">
          <div className="container">
            <Suspense
              fallback={
                <div className="center">
                  <MDBSpinner role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </MDBSpinner>
                </div>
              }
            >
              {routes}
            </Suspense>
            </div>
        </main>
        <Footer />
      </header>
    </Router>);
}

export default App;
