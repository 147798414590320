import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBTooltip,
  MDBDropdownLink,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdown,
  MDBCollapse
 } from 'mdb-react-ui-kit';

function Navbar(props) {
  const [showBasic, setShowBasic] = useState(false);

  return (<MDBNavbar expand='lg' light bgColor='white' className="fixed-top">
  <MDBContainer>
    <MDBNavbarBrand href='/' style={{ width: 90 }} className="d-none d-sm-block">
            <img
              src='/img/logo.png'
              alt=''
              loading='lazy'
              className="half-shadow"
              style={{ position: 'absolute', top: 5, height: 100, background: '#fff', borderBottomLeftRadius: 8, borderBottomRightRadius:8}}
            />
    </MDBNavbarBrand>
    <MDBNavbarBrand href='/' style={{ width: 90 }} className="d-block d-sm-none">
            <img
              src='/img/logo2.png'
              alt=''
              loading='lazy'
              className="half-shadow"
            />
    </MDBNavbarBrand>
    <MDBNavbarToggler
      aria-controls='navbarExample01'
      aria-expanded='false'
      aria-label='Toggle navigation'
      onClick={() => setShowBasic(!showBasic)}
    >
      <MDBIcon fas icon='bars' />
    </MDBNavbarToggler>
    <MDBCollapse navbar show={showBasic}>
      <MDBNavbarNav right className='mb-2 mb-lg-0'>
        <MDBNavbarItem active>
          <MDBNavbarLink aria-current='page' href='/'>
            Inicio
          </MDBNavbarLink>
        </MDBNavbarItem>
       
        <MDBNavbarItem>
          <MDBNavbarLink href='/servicios'>
          Servicios
          </MDBNavbarLink>
        </MDBNavbarItem>

        <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' style={{ cursor: 'pointer' }}>
                  Comodidades
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink href='/suite'>La Suite</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href='/single'>Single</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href='/doble'>Doble</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href='/triple'>Triple</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            

      

      <div className="d-block d-sm-none">
        <MDBBtn outline className='mx-2' color='dark' href='/reservaciones'>
          Reservar
        </MDBBtn>
        <MDBTooltip tag='a' wrapperProps={{ href: '/contactos' }} title="Contactenos">
          <MDBBtn floating className='me-2' color='dark'>
            <MDBIcon className='me-2' far icon="envelope" />
          </MDBBtn>
        </MDBTooltip>
        <MDBBtn floating className='me-2' style={{ backgroundColor: '#3b5998' }} href='#'>
          <MDBIcon fab icon='facebook-f' />
        </MDBBtn>
    </div>
    </MDBNavbarItem>
    </MDBNavbarNav>
    </MDBCollapse>

    <div className="d-none d-sm-block">
      <MDBBtn outline className='mx-2' color='dark' href='/reservaciones'>
        Reservar
      </MDBBtn>
      <MDBTooltip tag='a' wrapperProps={{ href: '/contactos' }} title="Contactenos">
        <MDBBtn floating className='me-2' color='dark'>
          <MDBIcon className='me-2' far icon="envelope" />
        </MDBBtn>
      </MDBTooltip>
      <MDBBtn floating className='me-2' style={{ backgroundColor: '#3b5998' }} href='#'>
        <MDBIcon fab icon='facebook-f' />
      </MDBBtn>
    </div>

  </MDBContainer>
</MDBNavbar>);
}

export default Navbar;