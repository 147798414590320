import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-white text-lg-left grey-900'>
      <MDBContainer className='p-4'>
        <MDBRow>
          <MDBCol lg='6' md='12' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase'>Hotel Kegler</h5>

            <p><MDBIcon fas icon="map-marker-alt" /> Curupayty c/Avda. Gaspar R. de Francia<br/>
            <MDBIcon fas icon="phone" /> (595) 71720040-071720541<br/>
            <MDBIcon fas icon="phone" /> (595) 71720040-071720542<br/>
            <MDBIcon far icon="envelope" /> info@hotelkegler.com</p>
            
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <h5 className='text-uppercase'>Menu</h5>

            <ul className='list-unstyled mb-0'>
              <li>
                <a href='/' className='text-white'>
                  Inicio
                </a>
              </li>
              <li>
                <a href='/servicios' className='text-white'>
                  Servicios
                </a>
              </li>
              <li>
                <a href='/suite' className='text-white'>
                  La Suite
                </a>
                |
                <a href='/single' className='text-white'>
                  Single
                </a>
                |
                <a href='/doble' className='text-white'>
                  Doble
                </a>
                |
                <a href='/triple' className='text-white'>
                  Triple
                </a>
              </li>
              <li>
                <a href='/reservaciones' className='text-white'>
                  Reservas
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
            <ul className='list-unstyled'>
              <li>
                <br/>
              </li>
              <li>
              <br/>
              </li>
              <li>
                <MDBIcon fab icon="cc-visa me-1" size='2x' />
                <MDBIcon fab icon="cc-mastercard me-1" size='2x' />
                <i className="fab fa-cc-amex me-1 fa-2x"></i>
                <i className="fab fa-cc-diners-club me-1 fa-2x"></i>
                <i className="fab fa-cc-discover me-1 fa-2x"></i>
              </li>
              <li>
                Aceptamos todas las tarjetas
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Todos los derechos:{' '}
        <a className='text-white' href='https://gabdesigns.net/'>
          GabDesigns!
        </a>
      </div>
    </MDBFooter>
  );
}