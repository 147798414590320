import React from 'react';
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement,
  MDBBtn,
  MDBIcon,
} from 'mdb-react-ui-kit';

export default function Hero() {
  const intro = (<div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
  <div className="d-flex justify-content-center align-items-center h-100">
    <div className="text-white text-center">
      <h1 className='mb-4 d-none d-sm-block'>Hotel Kegler</h1>
      <h5 className='mb-5'>Bienvenid@s</h5>
        <MDBBtn outline className="btn-outline-light btn-lg m-2 shadow-2-strong" href='/reservaciones'>
          <MDBIcon className='me-2' far icon="calendar-check" /> Reservar
        </MDBBtn>
        <MDBBtn className="btn-outline-light btn-lg m-2 shadow-2-strong" outline  href='/contactos'>
          <MDBIcon className='me-2' far icon="envelope" /> Contactar
        </MDBBtn>
    </div>
  </div>
</div>);

  return (<>
    <div className="d-block d-sm-none mb-4" style={{ marginTop: 65 }}>
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner style={{ height: 500 }}>
          {[0,1,2,3,4].map((c,i) => (
            <MDBCarouselItem itemId={c}>
              <MDBCarouselElement src={`/img/hero/${c}b.jpg`} alt='...' />
                {intro}
            </MDBCarouselItem>
          ))}

        </MDBCarouselInner>
      </MDBCarousel>
    </div>
    <div className="d-none d-sm-block">
      <MDBCarousel showIndicators showControls fade>
        <MDBCarouselInner>
          {[0,1,2,3,4].map((c,i) => (
            <MDBCarouselItem itemId={c}>
              <MDBCarouselElement src={`/img/hero/${c}.jpg`} alt='...' />
                {intro}
            </MDBCarouselItem>
          ))}

        </MDBCarouselInner>
      </MDBCarousel>
    </div>
    </>);
}